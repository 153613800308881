import { useContext } from "react";
import SingleRow from "../single-row/single-row.component";
import { SymptleContext } from "../../../context/symptle.context";

import "./row-container.styles.scss";

const RowContainer = () => {
  const { gameGrid } = useContext(SymptleContext);

  return (
    <div className="row-container">
      {gameGrid.map((row, index) => (
        <SingleRow key={`row-${index}`} row={row} />
      ))}
    </div>
  );
};

export default RowContainer;
