import "./single-row.styles.scss";
import SingleTile from "../single-tile/single-tile.component";

const SingleRow = ({ row }) => {
  const getTime = (tile) => {
    const tileTime = 1 * 300 + tile * 100;

    return tileTime;
  };

  return (
    <div className="single-row-container">
      {row.map((tile, index) => (
        <SingleTile
          key={`tile-${tile.id}row`}
          tile={tile}
          time={getTime(tile)}
        />
      ))}
    </div>
  );
};

export default SingleRow;
