import { useState, useContext } from "react";
import { ScreenContext } from "../../context/screen.context";
import { symsCopy } from "../../data";

import "./footer.styles.scss";

const Footer = () => {
  const { meetthesyms, footer } = symsCopy;
  const { screenRef } = useContext(ScreenContext);
  const [footerOpen, setFooterOpen] = useState(false);

  const toggleFooter = () => {
    setFooterOpen(!footerOpen);
  };

  return (
    <footer>
      <div className={`footer`}>
        <div className="references">
          <div
            className={`content ref ${
              screenRef.current === "meetthesyms" ? "active" : ""
            }`}
          >
            <div className="content-inner">
              <div className="top-section">
                <div className="button-box">
                  <button
                    className={`${footerOpen ? "active" : ""}`}
                    onClick={toggleFooter}
                  ></button>
                </div>

                <p
                  className="p-light nb"
                  dangerouslySetInnerHTML={{ __html: meetthesyms.nb }}
                ></p>
              </div>

              <div className={`bottom-section ${footerOpen ? "active" : ""}`}>
                <p className="ref-title">{meetthesyms.footer_title}</p>
                <ul>
                  {meetthesyms.footer_references.map((ref, index) => (
                    <li
                      key={`ref-${index}`}
                      dangerouslySetInnerHTML={{ __html: ref }}
                    ></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="pi">
            <div className="content">
              <div>{footer.date}</div>
              <div>{footer.code}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
