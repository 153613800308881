import { useContext, useEffect, useState } from "react";
import { symsData } from "../../../data";

import { SymptleContext } from "../../../context/symptle.context";
import "./single-tile.styles.scss";

const SingleTile = ({ tile }) => {
  const [activeClass, setActiveClass] = useState("");

  const { tileId, rowId } = useContext(SymptleContext);

  const time = 10;
  const { tileValue, tileColor } = tile;

  const { symptoms } = symsData;

  const [tileIcon, setTileIcon] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => setActiveClass("active"), time);
  }, []);

  useEffect(() => {
    const myIcon = symptoms.find((symptom) => {
      return symptom.id === tileValue;
    });
    if (myIcon) {
      setTileIcon(myIcon.icon);
    } else {
      setTileIcon(null);
    }
  }, [tileValue]);

  return (
    <div
      className={`single-tile-container ${activeClass} ${tileColor} ${
        tile.id === tileId + 1 && tile.row === rowId ? "hightlight" : ""
      }`}
    >
      {tileIcon && (
        <img
          src={require(`../../../assets/imgs/${tileIcon}-crop.png`)}
          alt={tileIcon}
        />
      )}
    </div>
  );
};

export default SingleTile;
