import { useContext, useEffect, useState } from "react";

import "./single-tile.styles.scss";
import { CrosswordContext } from "../../../context/crossword.context";

const SingleTile = ({ row, id, margin, order, rowId }) => {
  const [activeClass, setActiveClass] = useState("");

  const { currentTileId, currentRowId, answerArray, clickLetterTile } =
    useContext(CrosswordContext);

  const clickSlot = () => {
    clickLetterTile(row, id);
    document.getElementById("message").focus();
  };

  useEffect(() => {
    setTimeout(() => setActiveClass("active"), 100);
  }, []);

  return (
    <div
      onClick={clickSlot}
      className={`crossword-tile ${activeClass} ${
        id === currentTileId && row === currentRowId ? "hightlight" : ""
      } ${answerArray[row].complete ? "complete" : ""}
       ${row === currentRowId && !answerArray[row].complete ? "nearly" : ""}
       ${
         answerArray[row].entry.length === answerArray[row].entrylength &&
         !answerArray[row].complete
           ? "incorrect"
           : ""
       } ${id === 10 - margin ? "solid" : ""} tile-order-${order}`}
    >
      <div className="clue-number">{row + 1}</div>
      {answerArray[row].entry[id]}
    </div>
  );
};

export default SingleTile;
