import { useContext } from "react";
import { CrosswordContext } from "../../../context/crossword.context";
import "./keyboard-key.styles.scss";

const KeyboardKey = (data) => {
  const { singlekey, keyclass } = data;

  const { updateKeyboardEntry, clearRow, deleteInput } =
    useContext(CrosswordContext);

  const clickKey = () => {
    if (keyclass === "delete") {
      deleteInput();
    } else if (keyclass === "clear") {
      clearRow();
    } else {
      updateKeyboardEntry(singlekey);
    }

    document.getElementById("message").focus();
  };

  return (
    <button
      onClick={clickKey}
      className={`keyboard-key ${keyclass}`}
      dangerouslySetInnerHTML={{ __html: singlekey }}
    ></button>
  );
};

export default KeyboardKey;
