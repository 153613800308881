import { useContext, useState, useEffect } from "react";
import { ScreenContext } from "../../context/screen.context";
import { symsData, symsCopy } from "../../data";

import "./meet-syms-screen.styles.scss";

const MeetSymsScreen = () => {
  const { symptoms } = symsData;
  const { meetthesyms } = symsCopy;

  const [carouselSyms, setCarouselSyms] = useState(symptoms);
  const [activePip, setActivePip] = useState(4);
  //meetthesyms
  const {
    activeClass,
    updateNextScreen,
    lastScreen,
    setActiveClass,
    transitionEnd,
    updateTransitionClass,
  } = useContext(ScreenContext);

  const goBack = () => {
    if (activeClass === "visible") return;
    updateNextScreen(lastScreen);
    setActiveClass("visible");
  };

  useEffect(() => {
    updateTransitionClass();
    clickPip(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carouselForward = () => {
    const newCarouselSyms = carouselSyms.map((s) => {
      s.pos = s.pos === 0 ? 8 : s.pos - 1;
      s.class = setEntryClass(s.pos);

      if (s.pos === 4) setActivePip(s.id - 1);

      return s;
    });
    setCarouselSyms(newCarouselSyms);
  };

  const carouselBack = () => {
    const newCarouselSyms = carouselSyms.map((s) => {
      s.pos = s.pos === 8 ? 0 : s.pos + 1;
      s.class = setEntryClass(s.pos);

      if (s.pos === 4) setActivePip(s.id - 1);

      return s;
    });
    setCarouselSyms(newCarouselSyms);
  };

  const setEntryClass = (pos) => {
    let newClass = "";

    switch (pos) {
      case 4:
        newClass = "primary";
        break;
      case 3:
      case 5:
        newClass = "secondary";
        break;
      case 2:
      case 6:
        newClass = "tertiary";
        break;
      default:
        newClass = "";
    }

    return newClass;
  };

  const clickPip = (index) => {
    let diff = 4 - index;

    const newCarouselSyms = carouselSyms.map((s) => {
      s.pos = s.id - 1 + diff;

      if (s.pos > carouselSyms.length) s.pos = s.pos - carouselSyms.length;
      if (s.pos < 0) s.pos = s.pos + carouselSyms.length;

      // s.pos = s.pos === 8 ? 0 : s.pos + 1;
      s.class = setEntryClass(s.pos);

      if (s.pos === 4) setActivePip(s.id - 1);

      return s;
    });
    setCarouselSyms(newCarouselSyms);
  };

  return (
    <div
      className={`meet-the-syms content screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="inner-section">
        <h1>{meetthesyms.title}</h1>
        <p>{meetthesyms.description}</p>
      </div>
      <div className="syms-carousel">
        <div className="container">
          <div className="inner">
            {symptoms.map((symptom, index) => (
              <div
                key={`sym-${index}`}
                style={{ left: (symptom.pos / 8) * 100 + "%" }}
                className={`entry ${symptom.class}`}
              >
                <img
                  src={require(`../../assets/imgs/${symptom.icon}-large.png`)}
                  alt={symptom.name}
                />
              </div>
            ))}
          </div>

          <button className="left-button" onClick={carouselBack}></button>
          <button className="right-button" onClick={carouselForward}></button>
        </div>

        <div className="info">
          <h2 className="name">
            {symptoms[activePip].name}
            <sup>{symptoms[activePip].ss}</sup>
          </h2>
          <p className="percent">{symptoms[activePip].percent}</p>
          <p className="occurance">{meetthesyms.occurance}</p>
        </div>

        <div className="pip-container">
          {symptoms.map((symptom, index) => (
            <div
              key={`pip-${index}`}
              className={`pip ${index === activePip ? "active" : ""}`}
            ></div>
          ))}
        </div>
      </div>

      <div className="inner-section">
        <button className="standard-button back-button" onClick={goBack}>
          {meetthesyms.button_back}
        </button>
      </div>
    </div>
  );
};

export default MeetSymsScreen;
