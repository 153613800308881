import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenContext } from "../../context/screen.context";
import { symsCopy } from "../../data";
import "./header.component.scss";

import symptleLogo from "../../assets/imgs/symptle_logo.svg";
import pnhpuzzleLogo from "../../assets/imgs/pnhpuzzle_logo.svg";

const Header = () => {
  const { nav } = symsCopy;

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const [currentRoute, setCurrentRoute] = useState("");

  const {
    currentScreen,
    activeClass,
    setActiveClass,
    updateNextScreen,
    updateOverlay,
    screenRef,
  } = useContext(ScreenContext);

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setCurrentRoute(pathname);
    console.log("PATH NAME");
    setIsClicked(false);
  }, [pathname]);

  const clickBurger = () => {
    setIsClicked(!isClicked);
  };

  const clickHome = () => {
    if (activeClass === "visible" || pathname === "/") return;
    setActiveClass("visible");
    navigate("/");
    setIsClicked(false);
    updateOverlay("");
  };

  const clickIntro = () => {
    updateOverlay("");
    if (activeClass === "visible" || currentScreen === "intro") return;
    setActiveClass("visible");
    setIsClicked(false);
    updateNextScreen("intro");
  };

  const clickPatient = () => {
    updateOverlay("");
    if (activeClass === "visible" || currentScreen === "patients") return;
    setActiveClass("visible");
    setIsClicked(false);
    updateNextScreen("patients");
  };

  const clickSyms = () => {
    if (activeClass === "visible") return;

    updateNextScreen("meetthesyms", screenRef.current);
    setActiveClass("visible");
    setIsClicked(false);
  };

  const clickHowToPlay = () => {
    if (activeClass === "visible") return;
    updateNextScreen("howtoplay", screenRef.current);
    setActiveClass("visible");
    setIsClicked(false);
  };

  return (
    <header className={`active`}>
      <div className="inner">
        <div className={`content`}>
          <div className="lockup-container">
            <div
              className={`lockup lockup-lineup ${
                currentRoute !== "/symptle" && currentRoute !== "/pnhpuzzle"
                  ? "active"
                  : ""
              }`}
            >
              <img
                className="lineup-img"
                src={require(`../../assets/imgs/lineup.png`)}
                alt="Symptle"
              />
            </div>
            <div
              className={`lockup lockup-crossword ${
                currentRoute === "/pnhpuzzle" ? "active" : ""
              }`}
            >
              <img
                onClick={clickIntro}
                src={pnhpuzzleLogo}
                alt="The PNH Puzzle"
              />
            </div>
            <div
              className={`lockup lockup-symptle ${
                currentRoute === "/symptle" ? "active" : ""
              }`}
            >
              <img onClick={clickIntro} src={symptleLogo} alt="Symptle " />
            </div>
          </div>
        </div>

        <button
          className={`burger ${isClicked ? "active" : ""} ${
            currentRoute === "/symptle" || currentRoute === "/pnhpuzzle"
              ? "visible"
              : ""
          } `}
          onClick={clickBurger}
        >
          <div className="line line-1"></div>
          <div className="line line-2"></div>
          <div className="line line-3"></div>
        </button>
      </div>

      <div className={`panel ${isClicked ? "active" : ""}`}>
        <div className="inner">
          <ul>
            <li>
              <button
                className="standard-button nav-button"
                onClick={clickHome}
              >
                {nav.home}
              </button>
            </li>
            <li>
              <button
                className={`standard-button nav-button ${
                  currentRoute === "/symptle" || currentRoute === "/pnhpuzzle"
                    ? ""
                    : "hidden"
                }`}
                onClick={clickHowToPlay}
              >
                {nav.htp}
              </button>
            </li>
            <li>
              <button
                className="standard-button nav-button"
                onClick={clickSyms}
              >
                {nav.meet}
              </button>
            </li>
            <li>
              <button
                className={`standard-button nav-button ${
                  currentRoute === "/symptle" ? "" : "hidden"
                }`}
                onClick={clickPatient}
              >
                {nav.patient}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
