import { createContext, useEffect, useState } from "react";
import {
  GetWinData,
  SetRowData,
  SetGameComplete,
  SaveGameData,
  GetAllData,
  SaveFirstData,
} from "../utils/local-storage";
import { symsData } from "../data";

const ROW_COUNT = 6;
const ROW_LENGTH = 4;

export const SymptleContext = createContext({
  gameGrid: [],
  gameId: -1,
  answerArray: [],
  savedData: [],
  resultsArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  lastScreen: "",
});

export const SymptleProvider = ({ children }) => {
  const { patients } = symsData;

  const [currentGame, setCurrentGame] = useState(null);
  const [gameGrid, setGameGrid] = useState([]);
  const [gameId, setGameId] = useState(0);
  const [rowId, setRowId] = useState(0);
  const [tileId, setTileId] = useState(0);
  const [canSubmitRow, setCanSubmitRow] = useState(false);
  const [gameComplete, setGameComplete] = useState(0);

  const [answerArray, setAnswerArray] = useState([]);
  const [clickedArray, setClickedArray] = useState([]);
  const [resultsArray, setResultsArray] = useState([]);

  const [playerData, setPlayerData] = useState([]);

  useEffect(() => {
    SaveFirstData(patients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CREATE GAME

  const resetPlayerData = () => {
    SaveGameData(patients);
    setPlayerData(GetAllData("symptle"));
  };

  const setNewAnswerArray = (id) => {
    const newAnswerArray = patients.find((patient) => patient.id === id);
    setAnswerArray([...newAnswerArray.syms]);

    setGameId(id);

    const playData = GetWinData(patients, id);

    setTileId(0);
    setRowId(playData.progress.length);

    setGameGrid(setExistingTiles(playData.progress));

    const newResults = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let i = 0; i < playData.progress.length; i++) {
      const row = playData.progress[i];

      for (let j = 0; j < row.length; j++) {
        const entry = row[j];

        if (entry.tileColor === "correct") {
          newResults[entry.tileValue - 1] = "correct";
          continue;
        }

        if (entry.tileColor === "nearly") {
          if (newResults[entry.tileValue - 1] !== "correct")
            newResults[entry.tileValue - 1] = "nearly";
          continue;
        }
        if (entry.tileColor === "incorrect") {
          newResults[entry.tileValue - 1] = "incorrect";
          continue;
        }
      }
    }

    setResultsArray(newResults);
  };

  const setExistingTiles = (_progress) => {
    const newGrid = [];

    for (let i = 0; i < ROW_COUNT; i++) {
      let arr = [];

      const rowProgress = i < _progress.length ? true : false;

      for (let j = 0; j < ROW_LENGTH; j++) {
        let obj = {
          id: j + 1,
          row: i,
          tileValue: rowProgress ? _progress[i][j].tileValue : 0,
          tileColor: rowProgress ? _progress[i][j].tileColor : 0,
        };

        arr.push(obj);
      }
      newGrid.push(arr);
    }

    return newGrid;
  };

  const updateProgress = (rowData) => {
    SetRowData(rowData, gameId);
  };

  // END CREATE GAME

  // TILE MANAGEMENT

  const updateTileValue = (newValue) => {
    if (tileId >= ROW_LENGTH) return;
    const newGridArray = gameGrid.map((row, index) => {
      if (index === rowId) row[tileId].tileValue = newValue;
      return row;
    });

    addToClickedArray(newValue);
    setGameGrid(newGridArray);
    setTileId(tileId + 1);
    setCanSubmitRow(tileId === ROW_LENGTH - 1);
  };

  const deleteTile = () => {
    if (tileId === 0) return;
    setTileId(tileId - 1);

    deleteFromClickedArray();

    const newGridArray = gameGrid.map((row, index) => {
      if (index === rowId) {
        row[tileId - 1].tileValue = 0;
      }
      return row;
    });

    setGameGrid(newGridArray);
    setCanSubmitRow(false);
  };

  const addToClickedArray = (val) => {
    const newClickedArray = clickedArray.map((entry) => entry);
    newClickedArray.push(val);

    setClickedArray(newClickedArray);
  };

  const deleteFromClickedArray = (val, type) => {
    const newClickedArray = clickedArray.filter(
      (entry) => entry !== gameGrid[rowId][tileId - 1].tileValue
    );

    setClickedArray(newClickedArray);
  };

  const checkIfExists = (val, array) => {
    return array.includes(val);
  };

  const updateResults = (row) => {
    const newResultsArray = resultsArray.map((entry) => entry);
    for (let i = 0; i < row.length; i++) {
      if (
        row[i].tileColor === "correct" &&
        newResultsArray[row[i].tileValue - 1] !== "correct"
      ) {
        newResultsArray[row[i].tileValue - 1] = "correct";
      } else if (
        row[i].tileColor === "nearly" &&
        newResultsArray[row[i].tileValue - 1] !== "correct"
      ) {
        newResultsArray[row[i].tileValue - 1] = "nearly";
      } else if (
        row[i].tileColor === "incorrect" &&
        newResultsArray[row[i].tileValue - 1] !== "incorrect"
      ) {
        newResultsArray[row[i].tileValue - 1] = "incorrect";
      }
    }
    setResultsArray(newResultsArray);
  };

  const checkRowResults = () => {
    const currentRow = answerArray;
    const newGridArray = gameGrid.map((row, index) => {
      if (index === rowId) {
        const newRow = row.map((tile, index) => {
          if (tile.tileValue === currentRow[index]) {
            tile.tileColor = "correct";
          } else if (checkIfExists(tile.tileValue, currentRow)) {
            tile.tileColor = "nearly";
          } else {
            tile.tileColor = "incorrect";
          }

          return tile;
        });

        updateResults(newRow);

        return newRow;
      } else {
        return row;
      }
    });
    setGameGrid(newGridArray);
  };

  const checkIfComplete = (array, currentRow) => {
    let i = 0;

    for (const value of array) {
      if (value !== currentRow[i].tileValue) return false;
      i += 1;
    }

    return true;
  };

  // const resetGrid = () => {
  //   const newGrid = [];

  //   for (let i = 0; i < ROW_COUNT; i++) {
  //     let arr = [];

  //     for (let j = 0; j < ROW_LENGTH; j++) {
  //       let obj = {
  //         id: j + 1,
  //         row: i,
  //         tileValue: 0,
  //         tileColor: 0,
  //       };

  //       arr.push(obj);
  //     }
  //     newGrid.push(arr);
  //   }

  //   return newGrid;
  // };

  const manageGameComplete = (val) => {
    SetGameComplete(gameId);
    setGameComplete(1);
    const endVal = val;
    setTimeout(() => {
      setGameComplete(endVal);
      const newResultsArray = resultsArray.map((entry) => {
        return 0;
      });
      setResultsArray(newResultsArray);
    }, 2000);
  };

  const submitRow = () => {
    if (!canSubmitRow) return;
    setCanSubmitRow(false);
    checkRowResults();
    setClickedArray([]);

    updateProgress(gameGrid[rowId]);

    if (checkIfComplete(answerArray, gameGrid[rowId])) {
      manageGameComplete(2);
      return;
    }

    if (rowId >= ROW_COUNT - 1) {
      manageGameComplete(3);
      return;
    }

    setTileId(0);
    setRowId(rowId + 1);
  };

  // END TILE MANAGEMENT

  const value = {
    setNewAnswerArray,
    resultsArray,
    answerArray,
    tileId,
    rowId,
    gameId,
    updateTileValue,
    gameComplete,
    gameGrid,
    deleteTile,
    canSubmitRow,
    submitRow,
    currentGame,
    setCurrentGame,
    clickedArray,
    resetPlayerData,
    playerData,
  };

  return (
    <SymptleContext.Provider value={value}>{children}</SymptleContext.Provider>
  );
};
