import { useContext, useEffect, useState } from "react";
import { SymptleContext } from "../../../context/symptle.context";
import { ScreenContext } from "../../../context/screen.context";
import PatientProgress from "../patient-progress/patient-progress.component";

import { GetPatientProgress } from "../../../utils/local-storage";

import "./patient-container.styles.scss";

const PatientContainer = (data) => {
  const { patient } = data;

  const { updateNextScreen, setActiveClass } = useContext(ScreenContext);
  const { setNewAnswerArray, playerData } = useContext(SymptleContext);

  const [isComplete, setIsComplete] = useState([]);
  const [patientProgress, setPatientProgress] = useState([]);
  const [progressLength, setProgressLength] = useState(0);

  const clickPatient = () => {
    setNewAnswerArray(patient.id);
    updateNextScreen("gamescreen");
    setActiveClass("visible");
  };

  useEffect(() => {
    const progress = GetPatientProgress(patient.id);
    setIsComplete(progress.isComplete);
    setProgressLength(progress.progress.length);

    for (let i = progress.progress.length; i < 6; i++) {
      progress.progress.push([
        { tileValue: 3, tileColor: "" },
        { tileValue: 4, tileColor: "" },
        { tileValue: 5, tileColor: "" },
        { tileValue: 9, tileColor: "" },
      ]);
    }
    if (progress.progress.length < 6) {
    }

    setPatientProgress(progress.progress);
  }, [playerData]);

  return (
    <button
      onClick={clickPatient}
      className={`patient-container ${isComplete ? "complete" : ""}`}
    >
      <div className="details">
        <h2 className="t-standard">{patient.name}</h2>
        <p>{patient.description}</p>
      </div>
      <div className={`results`}>
        <div className={`progress ${progressLength === 0 ? "hidden" : ""}`}>
          {patientProgress.map((progress, index) => (
            <div className="row" key={`progress-row-${patient.id}-${index}`}>
              <PatientProgress
                progress={progress}
                isComplete={isComplete}
                rowid={index}
              />
            </div>
          ))}
        </div>
      </div>
    </button>
  );
};

export default PatientContainer;
