import { useContext, useState, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsCopy } from "../../../data";
import "../../howtoplay/howtoplay.styles.scss";

const HowToPlayScreen = () => {
  // howtoplay

  const { crossword } = symsCopy;
  const howtoplay = crossword.howtoplay;

  const {
    transitionEnd,
    activeClass,
    setActiveClass,
    updateNextScreen,
    updateTransitionClass,
    lastScreen,
  } = useContext(ScreenContext);

  const goBack = () => {
    if (activeClass === "visible") return;
    updateNextScreen(lastScreen);
    setActiveClass("visible");
  };

  useEffect(() => {
    updateTransitionClass();
  }, []);

  return (
    <div
      className={`how-to-play screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="inner-section">
        <h1>{howtoplay.title}</h1>
        <div
          className="text-group"
          dangerouslySetInnerHTML={{ __html: howtoplay.description }}
        ></div>
      </div>

      <div className="button-holder">
        <div className="cycle-holder">
          <div className="cycle-button"></div>
          <div className="cycle-button right"></div>
        </div>
        <p>{howtoplay.description2}</p>
      </div>

      <div className="inner-section">
        <div className="hint-container">
          <div className="hint-row-crossword">
            <div className="hint-block-crossword yellow"></div>
            <p>{howtoplay.hints[0]}</p>
          </div>

          <div className="hint-row-crossword">
            <div className="hint-block-crossword green"></div>
            <p>{howtoplay.hints[1]}</p>
          </div>

          <div className="hint-row-crossword">
            <div className="hint-block-crossword red"></div>
            <p>{howtoplay.hints[2]}</p>
          </div>
        </div>
      </div>

      <div className="button-holder">
        <button className="standard-button back-button" onClick={goBack}>
          {howtoplay.button_back}
        </button>
      </div>
    </div>
  );
};

// const { howtoplay_c } = symsCopy;

// const {
//   transitionEnd,
//   updateTransitionClass,
//   activeClass,
//   setActiveClass,
//   updateNextScreen,
// } = useContext(ScreenContext);

// const backToIntro = () => {
//   if (activeClass === "visible") return;
//   updateNextScreen("intro");
//   setActiveClass("visible");
// };

// useEffect(() => {
//   updateTransitionClass();
// }, []);

// return (
//   <div
//     className={`howtoplay-screen-container screen ${activeClass}`}
//     onTransitionEnd={() => transitionEnd()}
//   >
//     <h1>{howtoplay_c.title}</h1>
//     <p>{howtoplay_c.description}</p>
//     <p>{howtoplay_c.description2}</p>

//     <p>{howtoplay_c.description3}</p>

//     {howtoplay_c.hints.map((hint, index) => (
//       <p key={`footerlist-${index}`}>{hint}</p>
//     ))}

//     <div className="button-holder">
//       <button className="standard-button button-orange" onClick={backToIntro}>
//         {howtoplay_c.button_back}
//       </button>
//     </div>
//     <p>{howtoplay_c.help}</p>
//   </div>
// );

export default HowToPlayScreen;
