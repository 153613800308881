import { Routes, Route } from "react-router-dom";

import HomePage from "./components/home-page/home-page.component";
import CrosswordApp from "./components/crossword/crossword-app/crossword-app.component";
import SymptleApp from "./components/symptle/symptle-app/symptle-app.component";
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";
import LoadScreen from "./components/load-screen/load-screen.component";

import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/symptle" element={<SymptleApp />} />
        <Route path="/pnhpuzzle" element={<CrosswordApp />} />
        <Route path="*" element={<HomePage />} />
      </Routes>

      <Footer />

      <LoadScreen />
    </div>
  );
};

export default App;
