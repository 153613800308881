import { useContext, useEffect } from "react";
import { CrosswordContext } from "../../../context/crossword.context";
import { CrosswordData } from "../../../data";
import KeyboardRow from "../keyboard-row/keyboard-row.component";

import "./keyboard.styles.scss";

const Keyboard = () => {
  //gamescreen
  const { keyboard } = CrosswordData;
  const { deleteInput, currentTileId } = useContext(CrosswordContext);

  useEffect(() => {
    function deleteKeyDown(e) {
      if (e.key === "Backspace") {
        deleteInput();
      }
    }

    document.addEventListener("keydown", deleteKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", deleteKeyDown);
    };
  }, [currentTileId]);

  return (
    <div className={`crossword-keyboard`}>
      {keyboard.map((keys, index) => (
        <KeyboardRow key={`key-row-${index}`} row={keys} />
      ))}
    </div>
  );
};

export default Keyboard;
