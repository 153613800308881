import { useContext, useEffect, useState } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsData, symsCopy } from "../../../data";
import { SymptleContext } from "../../../context/symptle.context";

import diagnosticPdf from "../../../assets/pdf/13000596_PNH_diagnostic_algorithm_20240411_8a.pdf";
import casePdf from "../../../assets/pdf/13000596_Alexion_PNH_Case_study_20240415_07c.pdf";

import "./win-screen.styles.scss";

const WinScreen = () => {
  //win
  const { winscreen } = symsCopy.symptle;
  const { symptoms, patients } = symsData;

  const {
    activeClass,
    setActiveClass,
    updateTransitionClass,
    transitionEnd,
    updateNextScreen,
    setLastScreen,
    isFirefox,
  } = useContext(ScreenContext);

  const { gameComplete, answerArray, gameId } = useContext(SymptleContext);

  const [symptomsArray, setSymptomsArray] = useState([]);

  useEffect(() => {
    updateTransitionClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newArray = answerArray.map((id) => {
      const s = symptoms.find((element) => element.id === id);
      return s;
    });

    setSymptomsArray(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restartGame = () => {
    if (activeClass === "visible") return;
    setActiveClass("visible");
    updateNextScreen("patients");
  };

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("winscreen");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCasePdf = () => {
    window.open(`${casePdf}#toolbar=0`, "_blank");
  };

  const openDiagnosticPdf = () => {
    window.open(`${diagnosticPdf}#toolbar=0`, "_blank");
  };

  return (
    <div
      className={`win-screen-container screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <h1>{gameComplete === 2 ? winscreen.title_win : winscreen.title_fail}</h1>
      <div className="text-holder">
        <div className="patient-title">
          <h2>{patients[gameId - 1].name}</h2>
          <p>{patients[gameId - 1].description}</p>
        </div>
      </div>
      <div className="inner-section">
        <div className="symptoms symptoms-large">
          {symptomsArray.map((symptom, index) => (
            <div key={`result-sym-${index}`} className="symptom">
              <img
                src={require(`../../../assets/imgs/${symptom.icon}-crop.png`)}
                alt={symptom.name}
              />

              <p>{symptom.name}</p>
            </div>
          ))}
        </div>
        <div className="symptoms symptoms-small">
          {symptomsArray.map((symptom, index) => (
            <div key={`result-sym-${index}`} className="symptom">
              <img
                src={require(`../../../assets/imgs/${symptom.icon}-crop.png`)}
                alt={symptom.name}
              />

              <p>{symptom.name}</p>
            </div>
          ))}
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html: winscreen.description[gameId - 1],
          }}
        ></p>
      </div>

      <div className="button-holder">
        <button
          className="standard-button secondary-button button-orange"
          onClick={restartGame}
        >
          {winscreen.button_play}
        </button>
      </div>

      {!isFirefox ? (
        <div className="button-holder">
          <p>{winscreen.case_studies_text}</p>

          <button
            className="standard-button secondary-button"
            onClick={openCasePdf}
          >
            {winscreen.case_button}
          </button>
        </div>
      ) : (
        ""
      )}
      {!isFirefox ? (
        <div className="button-holder">
          <p>{winscreen.diagnostic_text}</p>

          <button
            className="standard-button secondary-button"
            onClick={openDiagnosticPdf}
          >
            {winscreen.diagnostic_button}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WinScreen;

// <h1>
// {gameComplete === 2 ? winscreen.title_win : winscreen.title_fail}
// </h1>
