import { useContext, useEffect } from "react";
import { CrosswordContext } from "../../../context/crossword.context";
import { CrosswordData } from "../../../data";

import "./question-panel.styles.scss";

const QuestionPanel = () => {
  //gamescreen
  const { symptoms } = CrosswordData;
  const { answerArray, currentRowId, cycleQuestion } =
    useContext(CrosswordContext);

  //   useEffect(() => {
  //     function deleteKeyDown(e) {
  //       if (e.key === "Backspace") {
  //         deleteInput();
  //       }
  //     }

  //     document.addEventListener("keydown", deleteKeyDown);

  //     // Don't forget to clean up
  //     return function cleanup() {
  //       document.removeEventListener("keydown", deleteKeyDown);
  //     };
  //   }, [currentTileId]);

  const clickRight = () => {
    cycleQuestion(1);
  };

  const clickLeft = () => {
    cycleQuestion(-1);
  };

  return (
    <div className="question-pannel">
      <button className="cycle-button" onClick={clickLeft}></button>
      <div className="inner">
        <img
          src={require(`../../../assets/imgs/${symptoms[currentRowId].icon}-crop.png`)}
        />
        <div className="queston">
          <div className="q-number">{currentRowId + 1}.</div>
          <div className="q-text">{symptoms[currentRowId].question}</div>
        </div>
      </div>
      <button className="cycle-button right" onClick={clickRight}></button>
    </div>
  );
};

export default QuestionPanel;
