import { useContext, useEffect, useState } from "react";
import { CrosswordProvider } from "../../../context/crossword.context";
import { ScreenContext } from "../../../context/screen.context";
import IntroScreen from "../intro-screen/intro-screen.component";
import HowToPlayScreen from "../howtoplay/howtoplay.component";
import GameScreen from "../game-screen/game-screen.component";
import MeetSymsScreen from "../../meet-syms-screen/meet-syms-screen.component";
import WinScreen from "../win-screen/win-screen.component";

function CrosswordApp() {
  const { updateGameScreen, currentScreen, updateNextScreen, screenRef } =
    useContext(ScreenContext);

  const [screen, setScreen] = useState("");

  useEffect(() => {
    setScreen(screenRef.current);
  }, [currentScreen]);

  useEffect(() => {
    const firstUpdate = () => {
      setTimeout(() => {
        updateNextScreen("intro");
        updateGameScreen();
      }, 100);
    };
    firstUpdate();
  }, []);

  return (
    <CrosswordProvider>
      {screen === "intro" ? (
        <IntroScreen />
      ) : screen === "gamescreen" ? (
        <GameScreen />
      ) : screen === "winscreen" ? (
        <WinScreen />
      ) : screen === "howtoplay" ? (
        <HowToPlayScreen />
      ) : screen === "meetthesyms" ? (
        <MeetSymsScreen />
      ) : null}
    </CrosswordProvider>
  );
}

export default CrosswordApp;
