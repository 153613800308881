import { SymptleContext } from "../../../context/symptle.context";
import { useContext, useEffect } from "react";

import "./submit-button.styles.scss";

const SubmitButton = () => {
  const { canSubmitRow, submitRow } = useContext(SymptleContext);

  useEffect(() => {
    function enterKeyDown(e) {
      if (e.key === "Enter") {
        submitRow();
      }
    }

    document.addEventListener("keydown", enterKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", enterKeyDown);
    };
  }, []);

  return (
    <button
      className={`submit-button link-fade ${canSubmitRow ? "active" : ""}`}
      onClick={submitRow}
    >
      <img src={require(`../../../assets/imgs/tick.png`)} alt="submit" />
    </button>
  );
};

export default SubmitButton;
