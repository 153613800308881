import { useContext, useEffect, useState } from "react";
import { ScreenContext } from "../../../context/screen.context";
import SymptomsContainer from "../symptoms-container/symptoms-container.component";
import RowContainer from "../row-container/row-container.component";
import { symsData } from "../../../data";
import { SymptleContext } from "../../../context/symptle.context";
import { GetPatientProgress } from "../../../utils/local-storage";

import "./game-board.styles.scss";

const GameBoard = () => {
  //gamescreen
  const { symptoms } = symsData;

  const [isComplete, setIsComplete] = useState(false);

  const {
    transitionEnd,
    updateTransitionClass,
    updateNextScreen,
    setActiveClass,
    activeClass,
    setLastScreen,
  } = useContext(ScreenContext);

  const { gameComplete, gameId } = useContext(SymptleContext);

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("gamescreen");

    const progress = GetPatientProgress(gameId);
    if (progress !== undefined) {
      setIsComplete(progress.isComplete);
    }
  }, []);

  useEffect(() => {
    if (gameComplete > 1) {
      updateNextScreen("winscreen");
      setActiveClass("visible");
    }
  }, [gameComplete]);

  const backToResult = () => {
    if (activeClass === "visible") return;
    updateNextScreen("winscreen");
    setActiveClass("visible");
  };

  return (
    <div
      className={`game-board-container screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <RowContainer />

      {isComplete === true ? (
        <div className="complete">
          <h2>Game complete!</h2>
          <button
            onClick={backToResult}
            className="standard-button  back-button"
          >
            Results
          </button>
        </div>
      ) : (
        <SymptomsContainer symptoms={symptoms} />
      )}
    </div>
  );
};

export default GameBoard;
