import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import diagnosticPdf from "../../assets/pdf/13000596_PNH_diagnostic_algorithm_20240411_8a.pdf";
import casePdf from "../../assets/pdf/13000596_Alexion_PNH_Case_study_20240415_07c.pdf";
import { ScreenContext } from "../../context/screen.context";
import { symsCopy } from "../../data";

import "./home-page.styles.scss";

const HomePage = () => {
  const navigate = useNavigate();

  const { home } = symsCopy;

  const {
    transitionEnd,
    updateTransitionClass,
    activeClass,
    setActiveClass,
    isFirefox,
  } = useContext(ScreenContext);

  useEffect(() => {
    updateTransitionClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playSymptle = () => {
    if (activeClass === "visible") return;

    setActiveClass("visible");

    navigate("/symptle");
  };

  const playCrossword = () => {
    if (activeClass === "visible") return;

    setActiveClass("visible");

    navigate("/pnhpuzzle");
  };

  return (
    <div className="landing">
      <div
        className={`screen ${activeClass}`}
        onTransitionEnd={() => transitionEnd()}
      >
        <div className="inner-section">
          <h1>{home.title}</h1>

          <p>{home.description}</p>
        </div>
        <button className="standard-button" onClick={playSymptle}>
          {home.symptle}
        </button>
        <button className="standard-button" onClick={playCrossword}>
          {home.crossword}
        </button>

        {!isFirefox ? (
          <div className="inner-section">
            <div className="resources">
              <h2 className="t-medium">{home.resources_text}</h2>
              <ul>
                <li>
                  <a
                    href={`${casePdf}#toolbar=0`}
                    without="true"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="link-button link-fade"
                  >
                    {home.case_studies_text}
                  </a>
                </li>
                <li>
                  <a
                    href={`${diagnosticPdf}#toolbar=0`}
                    without="true"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="link-button link-fade"
                  >
                    {home.diagnostic_text}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="inner-section">
          <a href="https://alexion.com/" className="link-fade">
            <img
              src={require(`../../assets/imgs/${home.alexion_logo}`)}
              className="alexion"
              alt={home.alexion_alt}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
