import { useContext, useEffect, useState } from "react";

import { SymptleProvider } from "../../../context/symptle.context";
import { ScreenContext } from "../../../context/screen.context";

import GameBoard from "../game-board/game-board.component";
import IntroScreen from "../intro-screen/intro-screen.component";
import WinScreen from "../win-screen/win-screen.component";
import PatientScreen from "../patient-screen/patient-screen.component";
import MeetSymsScreen from "../../meet-syms-screen/meet-syms-screen.component";
import HowToPlayScreen from "../howtoplay/howtoplay.component";

const SymptleApp = () => {
  const { updateGameScreen, currentScreen, updateNextScreen, screenRef } =
    useContext(ScreenContext);

  const [screen, setScreen] = useState("");

  useEffect(() => {
    setScreen(screenRef.current);
  }, [currentScreen]);

  useEffect(() => {
    const firstUpdate = () => {
      setTimeout(() => {
        updateNextScreen("intro");
        updateGameScreen();
      }, 100);
    };
    firstUpdate();
  }, []);

  return (
    <SymptleProvider>
      {screen === "intro" ? (
        <IntroScreen />
      ) : screen === "howtoplay" ? (
        <HowToPlayScreen />
      ) : screen === "meetthesyms" ? (
        <MeetSymsScreen />
      ) : screen === "patients" ? (
        <PatientScreen />
      ) : screen === "gamescreen" ? (
        <GameBoard />
      ) : screen === "winscreen" ? (
        <WinScreen />
      ) : null}
    </SymptleProvider>
  );
};

export default SymptleApp;
