import { useContext, useEffect, useState } from "react";
import { SymptleContext } from "../../../context/symptle.context";
import { ScreenContext } from "../../../context/screen.context";
import { GetPatientProgress } from "../../../utils/local-storage";

import "./patient-progress.styles.scss";

const PatientProgress = (data) => {
  const { progress, rowid } = data;

  return (
    <div className="patient-progress-row">
      {progress.map((tile, index) => (
        <div
          key={`progress-tile-${rowid}-${index}`}
          className={`tile ${tile.tileColor === "correct" ? "correct" : ""} ${
            tile.tileColor === "nearly" ? "nearly" : ""
          } ${tile.tileColor === "incorrect" ? "incorrect" : ""}`}
        ></div>
      ))}
    </div>
  );
};

export default PatientProgress;
