import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsCopy } from "../../../data";

import "./win-screen.styles.scss";
import diagnosticPdf from "../../../assets/pdf/13000596_PNH_diagnostic_algorithm_20240411_8a.pdf";

const WinScreen = () => {
  //win
  const { winscreen } = symsCopy.crossword;

  const {
    activeClass,
    setActiveClass,
    updateTransitionClass,
    transitionEnd,
    updateNextScreen,
    setLastScreen,
    isFirefox,
  } = useContext(ScreenContext);

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("winscreen");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restartGame = () => {
    if (activeClass === "visible") return;
    updateNextScreen("gamescreen");
    setActiveClass("visible");
  };

  const openPdf = () => {
    window.open(`${diagnosticPdf}#toolbar=0`, "_blank");
  };

  return (
    <div
      className={`win-screen-container screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="text-holder">
        <h1>{winscreen.title_win}</h1>
      </div>
      <div className="image-container">
        <img src={require(`../../../assets/imgs/lineup.png`)} alt="The Syms" />
      </div>
      <div className="text-holder">
        <div className="tile-row">
          <div className="tile">I</div>
          <div className="tile margin">S</div>
          <div className="tile">T</div>
          <div className="tile">H</div>
          <div className="tile">I</div>
          <div className="tile margin">S</div>
          <div className="tile">P</div>
          <div className="tile">N</div>
          <div className="tile">H</div>
          <div className="tile">?</div>
        </div>
      </div>
      <div className="text-holder">
        <p>{winscreen.description}</p>
      </div>
      <div className="button-holder">
        <button
          className="standard-button secondary-button button-orange"
          onClick={restartGame}
        >
          {winscreen.button_play}
        </button>
      </div>

      {!isFirefox ? (
        <div className="button-holder">
          <p>{winscreen.diagnostic_text}</p>

          <button
            className="standard-button secondary-button"
            onClick={openPdf}
          >
            {winscreen.diagnostic_button}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WinScreen;
