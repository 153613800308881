import { useEffect, useState } from "react";
import SymptomsButton from "../symptoms-button/symptoms-button.component";
import DeleteButton from "../delete-button/delete-button.component";
import SubmitButton from "../submit-button/submit-button.component";

import "./symptoms-container.styles.scss";

const SymptomsContainer = ({ symptoms }) => {
  const [topRow, setTopRow] = useState([]);
  const [bottomRow, setBottomRow] = useState([]);

  useEffect(() => {
    const top = symptoms.filter((symptom) => {
      return symptom.id < 6;
    });
    setTopRow(top);

    const bottom = symptoms.filter((symptom) => {
      return symptom.id > 5;
    });

    bottom.unshift({
      id: -2,
      name: "B",
      icon: "sym_1.png",
    });

    bottom.push({
      id: -1,
      name: "E",
      icon: "sym_1.png",
    });

    setBottomRow(bottom);
  }, []);

  return (
    <div className="control-panel">
      <div className="symptoms-container">
        <div className="top">
          {topRow.map((symptom, index) => (
            <SymptomsButton key={symptom.id} symptom={symptom} time={index} />
          ))}
        </div>
        <div className="bottom">
          {bottomRow.map((symptom, index) => {
            if (symptom.id === -2) {
              return <DeleteButton key={symptom.id}></DeleteButton>;
            } else if (symptom.id === -1) {
              return <SubmitButton key={symptom.id}></SubmitButton>;
            } else {
              return (
                <SymptomsButton
                  key={symptom.id}
                  symptom={symptom}
                  time={index}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SymptomsContainer;
