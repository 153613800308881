import { useEffect, useRef, useState } from "react";
import { symsData } from "../../data";

import "./load-screen.styles.scss";

const LoadScreen = () => {
  // load
  const { symptoms } = symsData;

  const counterRef = useRef(0);
  const arrayRef = useRef(0);
  const [loadArray, setLoadArray] = useState([]);

  const [isLoaded, setIsLoaded] = useState(2);

  useEffect(() => {
    let arr = [];

    setTimeout(() => {
      for (let i = 0; i < symptoms.length; i++) {
        arr.push(`${symptoms[i].icon}-large`);
        arr.push(`${symptoms[i].icon}-crop`);
      }
      setLoadArray(arr);
    }, 200);
  }, []);

  useEffect(() => {
    if (loadArray.length > 0) {
      arrayRef.current = loadArray.length;

      loadArray.forEach((symptom) => {
        const img = new Image();

        img.onload = function () {
          counterRef.current += 1;

          if (counterRef.current === loadArray.length) {
            setTimeout(() => {
              setIsLoaded(1);
            }, 500);
            setTimeout(() => {
              setIsLoaded(0);
            }, 1200);
          }
        };

        img.src = require(`../../assets/imgs/${symptom}.png`);
      });
    }
  }, [loadArray]);

  // const loadAssets = (arr) => {
  //   setTimeout(() => {
  //     arrayRef.current = arr.length;

  //     arr.forEach((symptom) => {
  //       const img = new Image();

  //       img.onload = function () {
  //         counterRef.current += 1;

  //         if (counterRef.current === loadArray.length) {
  //           setTimeout(() => {
  //             setIsLoaded(1);
  //           }, 100);
  //           setTimeout(() => {
  //             setIsLoaded(0);
  //           }, 800);
  //         }
  //       };

  //       img.src = require(`../../assets/imgs/${symptom}.png`);
  //     });
  //   }, 300);
  // };

  return (
    <div
      className={`load-screen-container ${isLoaded === 0 ? "" : "active"} ${
        isLoaded < 2 ? "fade" : ""
      }`}
    >
      <div className="inner">
        <div className="lineup">
          <img src={require(`../../assets/imgs/lineup.png`)} alt="The Syms" />
        </div>

        <div className="bar">
          <div className="bar-bg"></div>
          <div
            className="bar-top"
            style={{
              width: (counterRef.current / loadArray.length) * 100 + "%",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
