import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsData, symsCopy } from "../../../data";
import PatientContainer from "../patient-container/patient-container.component";
import { SymptleContext } from "../../../context/symptle.context";
import "./patient-screen.styles.scss";

const PatientScreen = () => {
  // patients
  const { patients } = symsData;
  const { patientselection } = symsCopy.symptle;

  const { transitionEnd, updateTransitionClass, activeClass, setLastScreen } =
    useContext(ScreenContext);

  const { resetPlayerData } = useContext(SymptleContext);

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("patients");
  }, []);

  // const resetProgress = () => {
  //   resetPlayerData();
  // };

  return (
    <div
      className={`patient-screen-container screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd("game")}
    >
      <div className="text-holder">
        <h1>{patientselection.title}</h1>

        {patients.map((patient, index) => (
          <PatientContainer key={`row-${index}`} patient={patient} />
        ))}

        <div className="image-container">
          <img
            src={require(`../../../assets/imgs/lineup.png`)}
            alt="The Syms"
          />
        </div>
      </div>
    </div>
  );
};

export default PatientScreen;

// <button className="reset-button" onClick={resetProgress}>
// Reset Progress
// </button>
