import { useContext, useState, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsCopy } from "../../../data";
import "../../howtoplay/howtoplay.styles.scss";

const HowToPlayScreen = () => {
  // howtoplay

  const { symptle } = symsCopy;
  const howtoplay = symptle.howtoplay;

  const {
    transitionEnd,
    activeClass,
    setActiveClass,
    updateNextScreen,
    updateTransitionClass,
    lastScreen,
  } = useContext(ScreenContext);

  const goBack = () => {
    if (activeClass === "visible") return;
    updateNextScreen(lastScreen);
    setActiveClass("visible");
  };

  useEffect(() => {
    updateTransitionClass();
  }, []);

  return (
    <div
      className={`how-to-play screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="inner-section">
        <h1>{howtoplay.title}</h1>
        <div
          className="text-group"
          dangerouslySetInnerHTML={{ __html: howtoplay.description }}
        ></div>
      </div>
      <div className="inner-section hint-container-symptle hint-symptle-large">
        <div className="hint-row">
          <div className="hint-box"></div>
          <div className="hint-box green"></div>
          <div className="hint-box"></div>
          <div className="hint-box"></div>
        </div>
        <p
          className=""
          dangerouslySetInnerHTML={{ __html: howtoplay.hints[0] }}
        ></p>

        <div className="hint-row">
          <div className="hint-box"></div>
          <div className="hint-box yellow"></div>
          <div className="hint-box"></div>
          <div className="hint-box"></div>
        </div>
        <p
          className=""
          dangerouslySetInnerHTML={{ __html: howtoplay.hints[1] }}
        ></p>

        <div className="hint-row">
          <div className="hint-box"></div>
          <div className="hint-box"></div>
          <div className="hint-box grey"></div>
          <div className="hint-box"></div>
        </div>
        <p
          className=""
          dangerouslySetInnerHTML={{ __html: howtoplay.hints[2] }}
        ></p>
      </div>

      <div className="inner-section hint-container-symptle hint-symptle-small">
        <div className="hint-row">
          <div className="hint-box green"></div>
          <p
            className=""
            dangerouslySetInnerHTML={{ __html: howtoplay.hints[0] }}
          ></p>
        </div>

        <div className="hint-row">
          <div className="hint-box yellow"></div>
          <p dangerouslySetInnerHTML={{ __html: howtoplay.hints[1] }}></p>
        </div>

        <div className="hint-row">
          <div className="hint-box grey"></div>
          <p dangerouslySetInnerHTML={{ __html: howtoplay.hints[2] }}></p>
        </div>
      </div>

      <div className="inner-section">
        <button className="standard-button back-button" onClick={goBack}>
          {howtoplay.button_back}
        </button>
      </div>

      <div className="text-group">
        <p
          className="p-light nb"
          dangerouslySetInnerHTML={{ __html: howtoplay.nb }}
        ></p>
      </div>
    </div>
  );
};

// const { howtoplay_c } = symsCopy;

// const {
//   transitionEnd,
//   updateTransitionClass,
//   activeClass,
//   setActiveClass,
//   updateNextScreen,
// } = useContext(ScreenContext);

// const backToIntro = () => {
//   if (activeClass === "visible") return;
//   updateNextScreen("intro");
//   setActiveClass("visible");
// };

// useEffect(() => {
//   updateTransitionClass();
// }, []);

// return (
//   <div
//     className={`howtoplay-screen-container screen ${activeClass}`}
//     onTransitionEnd={() => transitionEnd()}
//   >
//     <h1>{howtoplay_c.title}</h1>
//     <p>{howtoplay_c.description}</p>
//     <p>{howtoplay_c.description2}</p>

//     <p>{howtoplay_c.description3}</p>

//     {howtoplay_c.hints.map((hint, index) => (
//       <p key={`footerlist-${index}`}>{hint}</p>
//     ))}

//     <div className="button-holder">
//       <button className="standard-button button-orange" onClick={backToIntro}>
//         {howtoplay_c.button_back}
//       </button>
//     </div>
//     <p>{howtoplay_c.help}</p>
//   </div>
// );

export default HowToPlayScreen;
