import { createContext, useRef, useState, useEffect } from "react";

export const ScreenContext = createContext({
  lastScreen: "",
});

export const ScreenProvider = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState("");
  const [lastScreen, setLastScreen] = useState("");
  const [activeClass, setActiveClass] = useState("");
  const backRef = useRef("");
  const screenRef = useRef("");
  const [overlay, setOverlay] = useState("");
  const [gameType, setGameType] = useState("");

  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    const isFF = navigator.userAgent.toLowerCase().includes("firefox");
    setIsFirefox(isFF);
  }, []);

  // MANAGE SCREEN TRANSITIONS

  const updateGameScreen = () => {
    setCurrentScreen(screenRef.current);
    setActiveClass("visible");
  };

  const updateNextScreen = (val, last = "") => {
    screenRef.current = val;
  };

  const updateTransitionClass = () => {
    setTimeout(() => {
      setActiveClass("active");
    }, 100);
  };
  const transitionEnd = () => {
    if (activeClass === "visible") {
      setActiveClass("");
      updateGameScreen();
    }
  };

  const updateOverlay = (_val, _gameType) => {
    setGameType(_gameType);
    setOverlay(_val);
  };

  // END MANAGE SCREEN TRANSITIONS

  const value = {
    currentScreen,
    updateTransitionClass,
    transitionEnd,
    updateGameScreen,
    setActiveClass,
    activeClass,
    updateNextScreen,
    screenRef,
    backRef,
    lastScreen,
    setLastScreen,
    updateOverlay,
    overlay,
    gameType,
    setGameType,
    isFirefox,
  };

  return (
    <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  );
};
