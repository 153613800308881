const LOAD_KEY_SYMPTLE = "symp889";
const LOAD_KEY_CROSSWORD = "crossword889";

export const SaveGameData = (data) => {
  const key = LOAD_KEY_SYMPTLE;
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const SaveGameDataCrossword = (data) => {
  const key = LOAD_KEY_CROSSWORD;
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const GetAllData = (type) => {
  const key = type === "symptle" ? LOAD_KEY_SYMPTLE : LOAD_KEY_CROSSWORD;
  const data = JSON.parse(window.localStorage.getItem(key));

  return data;
};

export const SaveFirstData = (data) => {
  const key = LOAD_KEY_SYMPTLE;
  if (window.localStorage.getItem(key) === null) {
    SaveGameData(data);
  }
};

export const GetWinData = (_data, _id) => {
  const key = LOAD_KEY_SYMPTLE;
  if (window.localStorage.getItem(key) === null) {
    SaveGameData(_data);
  }

  const data = JSON.parse(window.localStorage.getItem(key));
  const patient = data.find((p) => {
    return p.id === _id;
  });

  return patient;
};

export const SetRowData = (row, gameId) => {
  const key = LOAD_KEY_SYMPTLE;
  const data = JSON.parse(window.localStorage.getItem(key));

  const rowArray = [];

  for (let i = 0; i < row.length; i++) {
    rowArray.push({
      tileValue: row[i].tileValue,
      tileColor: row[i].tileColor,
    });
  }

  const patient = data.find((p) => p.id === gameId);
  patient.progress.push(rowArray);

  SaveGameData(data);
};

export const SetGameComplete = (gameId) => {
  const key = LOAD_KEY_SYMPTLE;
  const data = JSON.parse(window.localStorage.getItem(key));
  const game = data.find((g) => g.id === gameId);
  game.isComplete = true;

  SaveGameData(data);
};

export const GetPatientProgress = (gameId) => {
  const key = LOAD_KEY_SYMPTLE;
  const data = JSON.parse(window.localStorage.getItem(key));
  const game = data.find((g) => g.id === gameId);

  return game;
};
