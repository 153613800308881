import SingleRow from "../single-row/single-row.component";

import "./row-container.styles.scss";

const RowContainer = ({ symptoms }) => {
  return (
    <div className="crossword-row-container">
      {symptoms.map((symptom, index) => (
        <div className="outer" key={`crossword-row-${index}`}>
          <SingleRow key={`row-${index}`} symptom={symptom} rowId={index} />
          {symptom.spacing ? (
            <div className="blank-row">
              <div
                className={`blank-tile ${
                  symptom.spacingText === "?" ? "blank-question" : "blank--"
                }`}
                key={`tile-${index}-below}`}
              >
                {symptom.spacingText}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default RowContainer;
