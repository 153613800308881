import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { symsCopy } from "../../../data";
import "./intro-screen.styles.scss";

const IntroScreen = () => {
  // intro
  const { intro } = symsCopy.crossword;

  const {
    transitionEnd,
    updateTransitionClass,
    activeClass,
    setActiveClass,
    updateNextScreen,
    setLastScreen,
  } = useContext(ScreenContext);

  const startGame = () => {
    if (activeClass === "visible") return;
    updateNextScreen("gamescreen");
    setActiveClass("visible");
  };

  const howToPlay = () => {
    if (activeClass === "visible") return;
    updateNextScreen("howtoplay");
    setActiveClass("visible");
  };

  const meetSyms = () => {
    if (activeClass === "visible") return;
    updateNextScreen("meetthesyms");
    setActiveClass("visible");
  };

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("intro");
  }, []);

  return (
    <div
      className={`crossword-intro screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="inner-section">
        <h1 dangerouslySetInnerHTML={{ __html: intro.title }}></h1>
        <p>{intro.description}</p>
      </div>

      <button className="standard-button button-orange" onClick={startGame}>
        {intro.button_play}
      </button>

      <div className="inner-section">
        <p>{intro.review_text}</p>

        <button className="standard-button button-orange" onClick={meetSyms}>
          {intro.button_meetthesyms}
        </button>
      </div>

      <button className="standard-button secondary-button" onClick={howToPlay}>
        {intro.button_howto}
      </button>
      <div className="image-container">
        <img src={require(`../../../assets/imgs/lineup.png`)} alt="The Syms" />
      </div>
    </div>
  );
};

export default IntroScreen;
