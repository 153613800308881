import { useEffect, useState, useContext } from "react";
import { SymptleContext } from "../../../context/symptle.context";

import "./symptoms-button.styles.scss";

const SymptomsButton = ({ symptom, time }) => {
  const { id, name, icon } = symptom;

  const { updateTileValue, clickedArray, resultsArray } =
    useContext(SymptleContext);

  const [isClicked, setIsClicked] = useState(false);
  const [activeClass, setActiveClass] = useState("");

  const clickSymptomButton = () => {
    if (isClicked) return;
    updateTileValue(id);
  };

  useEffect(() => {
    setIsClicked(clickedArray.includes(id));
  }, [clickedArray]);

  useEffect(() => {
    const timer = setTimeout(() => setActiveClass("active"), time * 100);
  }, []);

  return (
    <button
      className={`keyboard-button symptom-button ${activeClass} ${
        isClicked ? "clicked" : ""
      } ${resultsArray[id - 1] === "correct" ? "correct" : ""} ${
        resultsArray[id - 1] === "incorrect" ? "incorrect" : ""
      } ${resultsArray[id - 1] === "nearly" ? "nearly" : ""} `}
      onMouseDown={clickSymptomButton}
      style={{
        opacity: isClicked ? 0.6 : 1,
      }}
    >
      <img src={require(`../../../assets/imgs/${icon}-large.png`)} alt={name} />
    </button>
  );
};

export default SymptomsButton;
