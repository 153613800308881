import { createContext, useEffect, useState } from "react";
import { CrosswordData } from "../data";

import { SaveGameDataCrossword, GetAllData } from "../utils/local-storage";

export const CrosswordContext = createContext({
  currentRowId: 0,
  currentTileId: 0,
  answerArray: [],
  savedData: [],
  lastScreen: "",
});

export const CrosswordProvider = ({ children }) => {
  const { symptoms } = CrosswordData;

  const [currentGame, setCurrentGame] = useState(null);
  const [answerArray, setAnswerArray] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(0);
  const [currentTileId, setCurrentTileId] = useState(0);

  const [answerInput, setAnswerInput] = useState("");
  const [rowDirection, setRowDirection] = useState(0);
  const [gameComplete, setGameComplete] = useState(0);

  useEffect(() => {
    const savedData = GetAllData("crossword");
    if (savedData === null) {
      resetGame();
    } else {
      setAnswerArray(savedData.arr);
      setCurrentRowId(savedData.rowId);
      setCurrentTileId(savedData.tileId);
      clearAnswer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateEntry = (val) => {
    const newEntryArray = answerArray.map((answer, index) => {
      if (index === currentRowId) {
        answer.entry[currentTileId] = val;
        answer.entrylength = setEntryLength(answer.entry);
      }
      return answer;
    });

    setAnswerArray(newEntryArray);
  };

  const cycleQuestion = (dir) => {
    if (dir === 1) {
      const index = getNextUncompleteRow(currentRowId);
      setCurrentRowId(index);
    } else {
      const index = getPrevUncompleteRow(currentRowId);
      setCurrentRowId(index);
    }
  };

  const setEntryLength = (entry) => {
    let length = 0;

    for (let i = 0; i < entry.length; i++) {
      if (entry[i] !== "") length += 1;
    }

    return length;
  };

  const updateKeyboardEntry = (val) => {
    const entry = answerInput + val;

    setAnswerInput(entry);
    setCurrentTileId(currentTileId + 1);

    updateEntry(entry);
    clearAnswer();
    checkAnswer();

    setRowDirection(1);

    if (
      currentTileId >= answerArray[currentRowId].answer.length - 1 ||
      rowComplete()
    )
      stepForwardRow();
  };

  const updateAnswer = (event) => {
    setAnswerInput(event.target.value.toUpperCase());
    setCurrentTileId(currentTileId + 1);

    updateEntry(event.target.value.toUpperCase());
    clearAnswer();
    checkAnswer();
    setRowDirection(1);

    if (
      currentTileId >= answerArray[currentRowId].answer.length - 1 ||
      rowComplete()
    )
      stepForwardRow();

    SaveGameDataCrossword({
      arr: answerArray,
      tileId: currentTileId,
      rowId: currentRowId,
    });
  };

  const deleteInput = (isKeydown) => {
    setAnswerInput("");
    updateEntry("");

    setRowDirection(-1);

    if (currentTileId === 0) {
      stepBackRow();
    } else {
      setCurrentTileId(currentTileId - 1);
    }

    clearAnswer();
  };

  const clickLetterTile = (row, id) => {
    setCurrentRowId(row);
    setTimeout(() => {
      setCurrentTileId(id);
    }, 10);
  };

  useEffect(() => {
    if (rowDirection === -1) {
      setCurrentTileId(answerArray[currentRowId].entry.length - 1);
    } else if (rowDirection === 1) {
      setCurrentTileId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRowId]);

  const stepBackRow = () => {
    currentRowId === 0
      ? setCurrentRowId(answerArray.length - 1)
      : setCurrentRowId(currentRowId - 1);

    clearAnswer();
  };

  const stepForwardRow = () => {
    const index = getNextUncompleteRow(currentRowId);
    if (index > -1) {
      if (currentRowId === index) {
        setCurrentRowId(index);
        setCurrentTileId(0);
      } else {
        setCurrentRowId(index);
        clearAnswer();
      }
    } else {
      manageGameComplete();
    }
  };

  const manageGameComplete = (val) => {
    //SetGameComplete(gameId);
    setGameComplete(1);
    setTimeout(() => {
      setGameComplete(2);
      resetGame();
    }, 2000);
  };

  const getPrevUncompleteRow = (current) => {
    let j = current;

    let newIndex = -1;

    for (let i = answerArray.length; i > 0; i--) {
      j -= 1;
      if (j <= -1) j = answerArray.length - 1;

      if (!answerArray[j].complete) {
        newIndex = j;
        break;
      }
    }
    return newIndex;
  };

  const getNextUncompleteRow = (current) => {
    let j = current;

    let newIndex = -1;

    for (let i = 0; i < answerArray.length; i++) {
      j += 1;
      if (j >= answerArray.length) j = 0;

      if (!answerArray[j].complete) {
        newIndex = j;
        break;
      }
    }
    return newIndex;
  };

  const checkAnswer = () => {
    let tempAnswer = answerArray[currentRowId].entry.join("");

    if (tempAnswer.length > answerArray[currentRowId].answer.length) {
      tempAnswer = tempAnswer.substring(
        0,
        tempAnswer.length -
          (tempAnswer.length - answerArray[currentRowId].answer.length)
      );
    }

    if (tempAnswer === answerArray[currentRowId].answer) {
      const newArray = answerArray.map((arr, index) => {
        if (index === currentRowId) arr.complete = true;
        return arr;
      });

      setAnswerArray(newArray);
    }
  };

  const rowComplete = () => {
    let tempAnswer = answerArray[currentRowId].entry.join("");
    return tempAnswer === answerArray[currentRowId].answer;
  };

  const clearRow = () => {
    const newArray = answerArray.map((answer, index) => {
      if (index === currentRowId) {
        for (let i = 0; i < answer.entry.length; i++) {
          answer.entry[i] = "";
        }
      }
      return answer;
    });

    setCurrentTileId(0);
    setAnswerArray(newArray);

    SaveGameDataCrossword({
      arr: answerArray,
      tileId: 0,
      rowId: currentRowId,
    });
  };

  const clearAnswer = () => {
    const newAnswer = "";
    setAnswerInput(newAnswer);
  };

  const resetGame = () => {
    const arr = [];

    for (let i = 0; i < symptoms.length; i++) {
      arr.push({
        answer: symptoms[i].answer,
        entry: [],
        complete: false,
      });

      for (let j = 0; j < symptoms[i].answer.length; j++) {
        arr[i].entry.push("");
      }
    }
    setAnswerArray(arr);
    SaveGameDataCrossword({
      arr: arr,
      tileId: 0,
      rowId: 0,
    });

    setCurrentRowId(0);
    setCurrentTileId(0);
  };

  const value = {
    currentGame,
    setCurrentGame,
    updateAnswer,
    updateKeyboardEntry,
    clickLetterTile,
    currentTileId,
    setCurrentTileId,
    currentRowId,
    answerInput,
    answerArray,
    deleteInput,
    gameComplete,
    clearRow,
    cycleQuestion,
  };

  return (
    <CrosswordContext.Provider value={value}>
      {children}
    </CrosswordContext.Provider>
  );
};
