import { SymptleContext } from "../../../context/symptle.context";
import { useContext, useEffect } from "react";
import "./delete-button.styles.scss";

const DeleteButton = () => {
  const { deleteTile, tileId } = useContext(SymptleContext);

  useEffect(() => {
    function deleteKeyDown(e) {
      if (e.key === "Backspace") {
        deleteTile();
      }
    }

    document.addEventListener("keydown", deleteKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", deleteKeyDown);
    };
  }, []);

  return (
    <button
      className={`  link-fade delete-button ${tileId > 0 ? "active" : ""}`}
      onClick={deleteTile}
    >
      <img src={require(`../../../assets/imgs/back_button.png`)} alt="delete" />
    </button>
  );
};

export default DeleteButton;
