export const symsData = {
  symptoms: [
    {
      id: 1,
      name: "Abdominal pain",
      pos: 0,
      percent: "44%",
      class: 0,
      ss: "1",
      icon: "abdominal-pain",
    },
    {
      id: 2,
      name: "Anaemia",
      pos: 1,
      percent: "84-94%",
      class: 0,
      ss: "2",
      icon: "anaemia",
    },
    {
      id: 3,
      name: "Chest pain",
      pos: 4,
      percent: "33%",
      class: 0,
      ss: "1",
      icon: "chest-pain",
    },
    {
      id: 4,
      name: "Dysphagia",
      pos: 2,
      percent: "41%",
      class: 0,
      ss: "3",
      icon: "dysphagia",
    },
    {
      id: 5,
      name: "Dyspnoea",
      pos: 3,
      percent: "64%",
      class: 0,
      ss: "1",
      icon: "dyspnoea",
    },
    {
      id: 6,
      name: "Erectile dysfunction",
      pos: 5,
      percent: "38%",
      class: 0,
      ss: "1",
      icon: "erectile-dysfunction",
    },
    {
      id: 7,
      name: "Fatigue",
      pos: 6,
      percent: "80%",
      class: 0,
      ss: "1",
      icon: "fatigue",
    },
    {
      id: 8,
      name: "Haemoglobinuria",
      pos: 7,
      percent: "62%",
      class: 0,
      ss: "1",
      icon: "haemoglobinuria",
    },
    {
      id: 9,
      name: "Thrombotic events",
      pos: 8,
      percent: "40%",
      class: 0,
      ss: "4",
      icon: "thrombotic-events",
    },
  ],
  patients: [
    {
      id: 1,
      name: "Patient A",
      description: "35-year-old male teacher",
      syms: [7, 2, 6, 8],
      progress: [],
      isComplete: false,
    },
    {
      id: 2,
      name: "Patient B",
      description: "32-year-old woman, 18 weeks pregnant",
      syms: [2, 1, 8, 4],
      progress: [],
      isComplete: false,
    },
    {
      id: 3,
      name: "Patient C",
      description: "26-year-old male army recruit",
      syms: [7, 2, 8, 1],
      progress: [],
      isComplete: false,
    },
    {
      id: 4,
      name: "Patient D",
      description: "69-year-old retired woman",
      syms: [3, 9, 1, 5],
      progress: [],
      isComplete: false,
    },
  ],
};

export const symsCopy = {
  footer: {
    date: "April 2024",
    code: "UK/ULT-P/0253",
  },
  nav: {
    home: "Home",
    meet: "Meet the Syms",
    htp: "How to play",
    patient: "Choose patient",
  },
  home: {
    title: "Meet the Syms",
    description:
      "In varying combinations, certain signs and symptoms are typical of PNH. Solve these puzzles to get acquainted with the Syms:",
    symptle: "Play Symptle",
    crossword: "Play The PNH Puzzle",
    resources_text: "PNH resources",
    case_studies_text: "PNH case studies",
    diagnostic_text: "PNH diagnostic algorithm",
    alexion_logo: "alexion_logo.png",
    alexion_alt: "Alexion - AstraZeneca Rare Disease",
  },
  symptle: {
    intro: {
      title: "Welcome to Symptle!",
      description:
        "A logic puzzle based on the presenting signs and symptoms of patients subsequently diagnosed with paroxysmal nocturnal haemoglobinuria",
      button_play: "Play",
      button_howto: "How to play",
      button_meetthesyms: "Meet the Syms",
    },
    howtoplay: {
      title: "How to play",
      description:
        "<p>Can you work out the four signs and symptoms of PNH in the order they presented for each of the fictitious patients?</p><p>You have <b>six tries</b> per patient.</p><p>Choose <b>any four</b> from the nine symptom tiles.</p><p>The colour will change depending on your guess.</p>",
      description2: "",
      hints: [
        "<b>Green</b> means right symptom, right position",
        "<b>Yellow</b> means right symptom, wrong position",
        "A <b>grey tile</b> means the symptom doesn’t belong",
      ],
      button_back: "Back",
      help: "? Click this symbol at any time to reveal a reminder of the nine symptoms and their % occurrence in PNH",
      nb: "<b>NB:</b> for the purposes of this game our fictitious patients have four of these presenting symptoms each, in a particular order. In reality, patients may present with a different order or number of presenting symptoms.",
    },
    patientselection: {
      title: "Choose a 'patient'",
    },
    winscreen: {
      title_win: "Success!",
      title_fail: "The Answer",
      description: [
        "<strong>Think PNH: Refer to haematologist - especially when, as in this case, there's also persistent anaemia despite iron replacement</strong>",
        "<strong>Think PNH: Check patient history for hidden symptoms - they may not all present at the same time</strong>",
        "<strong>Think PNH: Look out for unexplained iron-deficiency anaemia - consider assessing for haemolysis and haemoglobinuria</strong>",
        "<strong>Think PNH: Only around 6 in 10 patients will present with haemoglobinuria, so it can't always be relied on for diagnosis</strong>",
      ],
      button_play: "Play again",
      case_studies_text: "View the real patient cases that inspired the game",
      case_button: "Case studies",
      diagnostic_text: "View the diagnostic algorithm",
      diagnostic_button: "Diagnostic algorithm",
    },
  },
  crossword: {
    intro: {
      title: "Welcome to<br/>The PNH Puzzle!",
      description:
        "A crossword puzzle based on the presenting signs and symptoms of patients subsequently diagnosed with paroxysmal nocturnal haemoglobinuria",
      button_play: "Play",
      button_howto: "How to play",
      button_meetthesyms: "Meet the Syms",
      review_text:
        "To review the Syms before you start the puzzle, click here:",
    },
    howtoplay: {
      title: "How to play",
      description:
        "<p>You are presented with nine cryptic clues, each corresponding to a row in the crossword.</p><p>Completing all rows will reveal a hidden phrase.</p>",
      description2: "Cycle through the clues using the arrows",
      hints: [
        "Letters are entered into the block highlighted yellow",
        "The blocks will turn green if a correct answer is entered",
        "The blocks will turn red if an incorrect answer is entered",
      ],
      button_back: "Back",
      help: "? Click this symbol at any time to reveal a reminder of the nine symptoms and their % occurrence in PNH",
    },
    winscreen: {
      title_win: "Success!",
      title_fail: "The Answer",
      description:
        "In varying combinations, any of the nine signs and symptoms shown here are typical of PNH and could be your first clue to early diagnosis.",
      description2:
        "The fictional patients in this game are inspired by real cases. For original case studies, with expert commentary, watch the webinar here",
      button_play: "Play again",
      diagnostic_text: "View the diagnostic algorithm",
      diagnostic_button: "Diagnostic algorithm",
    },
  },
  meetthesyms: {
    title: "Meet the Syms",
    description:
      "In varying combinations, these signs and symptoms are typical of PNH – and could be your first clue to early diagnosis. We’ve depicted them as nine characters – the Syms.",
    occurance: "occurrence in PNH",
    button_start: "Start",
    button_back: "Back",
    footer_title: "References:",
    footer_references: [
      "<strong>1. </strong>Schrezenmeier, H <i>et al</i>., Haematologica. 2014;99:922-929.",
      "<strong>2. </strong>Nishimura, J-I <i>et al</i>., Medicine. 2004;83:193-207.",
      "<strong>3. </strong>Weitz, I <i>et al</i>., Intern Med J. 2013;43(3):298-307.",
      "<strong>4. </strong>Devalet, B <i>et al</i>., Eur J Haematol.2015;95(3):190-198.",
    ],
    nb: "<strong>NB:</strong> for the purposes of this game our fictitious patients have four of these presenting symptoms each, in a particular order. In reality, patients may present with a different order or number of presenting symptoms.",
  },
};

export const CrosswordData = {
  symptoms: [
    {
      id: 0,
      name: "Haemoglobinuria",
      answer: "HAEMOGLOBINURIA",
      question: "A wee bit of blood?",
      entry: "",
      entrylength: 0,
      pos: 7,
      percent: "62%",
      spacing: false,
      class: 0,
      margin: 1,
      icon: "haemoglobinuria",
    },
    {
      id: 1,
      name: "Erectile dysfunction",
      answer: "ERECTILEDYSFUNCTION",
      question: "Man down?",
      entry: "",
      entrylength: 0,
      pos: 5,
      percent: "38%",
      spacing: true,
      spacingText: "",
      class: 0,
      margin: 0,
      icon: "erectile-dysfunction",
    },
    {
      id: 2,
      name: "Fatigue",
      answer: "FATIGUE",
      question: "Energy crisis?",
      entry: "",
      entrylength: 0,
      pos: 6,
      percent: "80%",
      class: 0,
      margin: 8,
      icon: "fatigue",
    },
    {
      id: 3,
      name: "Dysphagia",
      answer: "DYSPHAGIA",
      question: "It's hard to swallow, for Greeks",
      entry: "",
      entrylength: 0,
      pos: 2,
      percent: "41%",
      class: 0,
      margin: 6,
      icon: "dysphagia",
    },
    {
      id: 4,
      name: "Thrombotic Events",
      answer: "THROMBOTICEVENTS",
      question: "What clots put in their diaries?",
      entry: "",
      entrylength: 0,
      pos: 8,
      percent: "40%",
      class: 0,
      margin: 2,
      icon: "thrombotic-events",
    },
    {
      id: 5,
      name: "Dyspnoea",
      answer: "DYSPNOEA",
      question: "Breath-wise, it's pants",
      entry: "",
      entrylength: 0,
      pos: 3,
      percent: "64%",
      spacing: true,
      spacingText: "",
      class: 0,
      margin: 8,
      icon: "dyspnoea",
    },
    {
      id: 6,
      name: "Abdominal Pain",
      answer: "ABDOMINALPAIN",
      question: "A gut feeling, and not a nice one",
      entry: "",
      entrylength: 0,
      pos: 0,
      percent: "44%",
      class: 0,
      margin: 1,
      icon: "abdominal-pain",
    },
    {
      id: 7,
      name: "Anaemia",
      answer: "ANAEMIA",
      question: "Down with red blood cells!",
      entry: "",
      entrylength: 0,
      pos: 1,
      percent: "84-94%",
      class: 0,
      margin: 9,
      icon: "anaemia",
    },
    {
      id: 8,
      name: "Chest pain",
      answer: "CHESTPAIN",
      question: "The hurt a pirate feels after losing his treasure box?",
      entry: "",
      pos: 4,
      percent: "33%",
      spacing: true,
      spacingText: "?",
      class: 0,
      margin: 9,
      icon: "chest-pain",
    },
  ],
  keyboard: [
    [
      { class: "letter", key: "Q" },
      { class: "letter", key: "W" },
      { class: "letter", key: "E" },
      { class: "letter", key: "R" },
      { class: "letter", key: "T" },
      { class: "letter", key: "Y" },
      { class: "letter", key: "U" },
      { class: "letter", key: "I" },
      { class: "letter", key: "O" },
      { class: "letter", key: "P" },
    ],
    [
      { class: "letter", key: "A" },
      { class: "letter", key: "S" },
      { class: "letter", key: "D" },
      { class: "letter", key: "F" },
      { class: "letter", key: "G" },
      { class: "letter", key: "H" },
      { class: "letter", key: "J" },
      { class: "letter", key: "K" },
      { class: "letter", key: "L" },
    ],
    [
      { class: "delete", key: "BACK<br/>SPACE" },
      { class: "letter", key: "Z" },
      { class: "letter", key: "X" },
      { class: "letter", key: "C" },
      { class: "letter", key: "V" },
      { class: "letter", key: "B" },
      { class: "letter", key: "N" },
      { class: "letter", key: "M" },
      { class: "clear", key: "CLEAR<br/>ROW" },
    ],
  ],
};

export const CrosswordData2 = {
  symptoms: [
    {
      id: 0,
      name: "Haemoglobinuria",
      answer: "HAEMOGLOBINURIA",
      question: "1. A wee bit of blood?",
      entry: "",
      entrylength: 0,
      pos: 7,
      percent: "62%",
      spacing: false,
      class: 0,
      margin: 1,
      icon: "haemoglobinuria",
    },
    {
      id: 1,
      name: "Erectile dysfunction",
      answer: "ERECTILEDYSFUNCTION",
      question: "2. Man down?",
      entry: "",
      entrylength: 0,
      pos: 5,
      percent: "38%",
      spacing: true,
      spacingText: "",
      class: 0,
      margin: 0,
      icon: "erectile-dysfunction",
    },
    {
      id: 2,
      name: "Fatigue",
      answer: "FATIGUE",
      question: "3. Energy crisis?",
      entry: "",
      entrylength: 0,
      pos: 6,
      percent: "80%",
      class: 0,
      margin: 8,
      icon: "fatigue",
    },
    {
      id: 3,
      name: "Dysphagia",
      answer: "DYSPHAGIA",
      question: "4. It's hard to swallow, for Greeks",
      entry: "",
      entrylength: 0,
      pos: 2,
      percent: "41%",
      class: 0,
      margin: 6,
      icon: "dysphagia",
    },
    {
      id: 4,
      name: "Thrombotic Events",
      answer: "THROMBOTICEVENTS",
      question: "5. What clots put in their diaries?",
      entry: "",
      entrylength: 0,
      pos: 8,
      percent: "40%",
      class: 0,
      margin: 2,
      icon: "thrombotic-events",
    },
    {
      id: 5,
      name: "Dyspnoea",
      answer: "DYSPNOEA",
      question: "6. Breath-wise, it's pants",
      entry: "",
      entrylength: 0,
      pos: 3,
      percent: "64%",
      spacing: true,
      spacingText: "",
      class: 0,
      margin: 8,
      icon: "dyspnoea",
    },
    {
      id: 6,
      name: "Abdominal Pain",
      answer: "ABDOMINALPAIN",
      question: "7. A gut feeling, and not a nice one",
      entry: "",
      entrylength: 0,
      pos: 0,
      percent: "44%",
      class: 0,
      margin: 1,
      icon: "abdominal-pain",
    },
    {
      id: 7,
      name: "Anaemia",
      answer: "ANAEMIA",
      question: "8. Down with red blood cells!",
      entry: "",
      entrylength: 0,
      pos: 1,
      percent: "84-94%",
      class: 0,
      margin: 9,
      icon: "anaemia",
    },
    {
      id: 8,
      name: "Chest pain",
      answer: "CHESTPAIN",
      question: "9. The hurt a pirate feels after losing his treasure box?",
      entry: "",
      pos: 4,
      percent: "33%",
      spacing: true,
      spacingText: "?",
      class: 0,
      margin: 9,
      icon: "chest-pain",
    },
  ],
  keyboard: [
    [
      { class: "letter", key: "Q" },
      { class: "letter", key: "W" },
      { class: "letter", key: "E" },
      { class: "letter", key: "R" },
      { class: "letter", key: "T" },
      { class: "letter", key: "Y" },
      { class: "letter", key: "U" },
      { class: "letter", key: "I" },
      { class: "letter", key: "O" },
      { class: "letter", key: "P" },
    ],
    [
      { class: "letter", key: "A" },
      { class: "letter", key: "S" },
      { class: "letter", key: "D" },
      { class: "letter", key: "F" },
      { class: "letter", key: "G" },
      { class: "letter", key: "H" },
      { class: "letter", key: "J" },
      { class: "letter", key: "K" },
      { class: "letter", key: "L" },
    ],
    [
      { class: "letter", key: "Z" },
      { class: "letter", key: "X" },
      { class: "letter", key: "C" },
      { class: "letter", key: "V" },
      { class: "letter", key: "B" },
      { class: "letter", key: "N" },
      { class: "letter", key: "M" },
      { class: "delete", key: "DELETE" },
    ],
  ],
};
