import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screen.context";
import { CrosswordContext } from "../../../context/crossword.context";
import RowContainer from "../row-container/row-container.component";
import QuestionPanel from "../question-panel/question-panel.component";
import { CrosswordData } from "../../../data";

import "./game-screen.styles.scss";
import Keyboard from "../keyboard/keyboard.component";

const GameScreen = () => {
  //gamescreen
  const { symptoms } = CrosswordData;

  const { updateAnswer, answerInput, gameComplete } =
    useContext(CrosswordContext);

  const {
    transitionEnd,
    updateTransitionClass,
    updateNextScreen,
    setActiveClass,
    activeClass,
    setLastScreen,
  } = useContext(ScreenContext);

  useEffect(() => {
    updateTransitionClass();
    setLastScreen("gamescreen");

    function is_touch_enabled() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    const myInput = document.getElementById("message");

    if (is_touch_enabled()) {
      myInput.setAttribute("readonly", "readonly");
    } else {
      // do nothing
    }

    myInput.focus();
  }, []);

  const is_touch_enabled = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  const clickBoard = () => {
    document.getElementById("message").focus();
  };

  useEffect(() => {
    if (gameComplete > 1) {
      updateNextScreen("winscreen");
      setActiveClass("visible");
    }
  }, [gameComplete]);

  return (
    <div
      onClick={clickBoard}
      className={`crossword-game screen ${activeClass}`}
      onTransitionEnd={() => transitionEnd()}
    >
      <div className="section-inner">
        <RowContainer symptoms={symptoms} />
      </div>
      <QuestionPanel />
      <Keyboard />
      <input
        type="text"
        id="message"
        name="message"
        value={answerInput}
        onChange={updateAnswer}
      />
    </div>
  );
};

export default GameScreen;
