import "./single-row.styles.scss";
import SingleTile from "../single-tile/single-tile.component";
import { useEffect, useState } from "react";

const SingleRow = ({ symptom }) => {
  const { answer, id, margin } = symptom;

  const [answerArray, setAnswerarray] = useState([]);

  useEffect(() => {
    const letterArray = answer.split("");
    setAnswerarray(letterArray);
  }, []);

  return (
    <div className={`crossword-row cw-spacing-${margin}`}>
      {answerArray.map((letter, index) => (
        <SingleTile
          key={`tile-${index}row${id}`}
          order={index}
          id={index}
          row={id}
          margin={margin}
        />
      ))}
    </div>
  );
};

export default SingleRow;
