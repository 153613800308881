import KeyboardKey from "../keyboard-key/keyboard-key.component";

import "./keyboard-row.styles.scss";

const KeyboardRow = (data) => {
  const { row } = data;
  return (
    <div className={`keyboard-row`}>
      {row.map((singlekey) => (
        <KeyboardKey
          key={`keyboard-row-${singlekey.key}`}
          singlekey={singlekey.key}
          keyclass={singlekey.class}
        />
      ))}
    </div>
  );
};

export default KeyboardRow;
